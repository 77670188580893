import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import Blogs from "../components/blogs";
import Contact from "../components/contact";
import Map from "../components/maps";

import data from "../../siteData";
import meta from "../../metaTags";
import catalog from "../../content/catalog.json";

export default function IndexPage() {
  return (
    <Layout isHome>
      <SEO
        description={meta.designation.description}
        lang={meta.designation.lang}
        meta={meta.designation.meta}
        keywords={meta.designation.keywords}
        title={meta.designation.title}
        data={meta.designation.data}
        image={meta.designation.data.logo}
      />
      <Banner data={data.aboutMe}></Banner>

      <Blogs models={catalog.models} />

      <Contact idenfier="landing" />
      <Map />
    </Layout>
  );
}
