import React from "react";
import { Link } from "gatsby";

export default function Blogs({ models }) {
  return (
    <div className="blogs-section section" id="catalog">
      <div className="container">
        <div className="section-head">
          <h2>Catálogo</h2>
        </div>
        <ul className={`blogs-list ${models.length < 5 ? "few-blogs" : ""}`}>
          {models.map((model, index) => {
            return (
              <li key={index} className="item">
                <div className="inner">
                  <Link className="link" to={`/${model.slug.toLowerCase()}`} />
                  {model.images.length ? (
                    <img src={model.images[0]} />
                  ) : (
                    <div className="no-image"></div>
                  )}
                  <div className="details">
                    <h3 className="title">{model.name}</h3>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
