import React from "react";
import Video from "../../static/images/videoBanner.mp4";
import Image from "../../static/images/imageLoad.png";

export default function Banner({ data }) {
  return (
    <div className="banner">
      {/*
        <Img
          fluid={data.bannerImage.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        */}
      <video
        autoPlay
        muted
        loop
        poster={Image}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          height: "100%",
          width: "100%",
          zIndex: "-1",
          objectFit: "fill",
          objectPosition: "center",
        }}
        src={Video}
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="container">
        <div className="banner-details">
          <span>Una nueva opción de movilidad</span>
          <h1>{data.designation}</h1>
          <ul className="sub-data">
            {data.bannerList.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
          <ul className="social">
            <li>
              <a
                className="fab fa-facebook-f"
                href={data.facebook}
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </li>
            <li>
              <a
                className="fab fa-twitter"
                href={data.twitter}
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </li>
            <li>
              <a
                className="fab fa-instagram"
                href={data.instagram}
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </li>
            <li>
              <a
                className="fab fa-tiktok"
                href={data.tiktok}
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
